@font-face {
  font-family: lovelyn;
  src: url("./assets/fonts/lovelyn.ttf");
}

@font-face {
  font-family: lovelace;
  src: url("./assets/fonts/Lovelace.otf");
}

body {
  margin: 0;
  font-family: "lovelace";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.homepage-hero-image {
  background-image: url("./assets/bg/bg.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 100%; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 100vh;
}

.homepage-image {
  border-radius: 50%;
  width: 85%;
  border: 2px white solid;
  position: relative;
  opacity: 0.5;
}

.homepage-container {
  position: absolute;
  top: 25vh;
}

.homepage-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.homepage-image:hover {
  opacity: 1;
}

.template-page-hero-image {
  background-image: url("./assets/bg/template-hero.png");
  background-color: #cccccc; /* Used if the image is unavailable */
  /* height: 100vh; */
  /* background-position: center;
  background-repeat: no-repeat;*/
  background-size: cover;
  min-height: 100vh;
  color: white;
}

.template-page-wrapper {
  background-color: #13294b;
}

.template-page-title {
  display: block;
  padding: 2em;
  text-align: center;
  font-size: 2.5em;
  font-weight: bolder;

  /* display: inline-block; */
  /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.template-page-heading {
  display: block;
  padding: 2em;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

.template-page-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.template-page-text {
  font-size: 1.2em;
  padding: 1em;
}

.modal-overlay {
  background: white;
  max-width: 400px;
  border-radius: 20px;
  border: 0;
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 10%);
  animation: fadeIn 1s ease both;
}

.modal-overlay p {
  font-size: 1rem;
  line-height: 1.3rem;
  padding: 0.5rem 0;
}

.modal-overlay h2 {
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 1rem;
}

.modal-overlay .x {
  filter: grayscale(1);
  border: none;
  background: none;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: ease filter, transform 0.3s;
  cursor: pointer;
  transform-origin: center;
}

.modal-overlay .x:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

.modal-overlay::backdrop {
  animation: fadeIn 1s ease both;
  background: rgb(255 255 255 / 40%);
  z-index: 2;
  backdrop-filter: blur(20px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.information-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.information-content {
  padding: 4rem;
}

.information-content-title {
  margin: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
}
.information-content-text {
  margin: 1rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  line-height: 1em;
}

.information-content-text .information-content-image {
  margin: 1rem;
  display: block;
}

.information-lhs {
  width: 30%;
}

.information-rhs {
  width: 70%;
}

.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: skyblue;
  justify-content: center;
  flex-direction: column;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
  cursor: pointer;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fcf;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #fcf;
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

@media (min-width: 320px) and (max-width: 999px) {
  .information-wrapper {
    flex-direction: column;
  }
  .information-content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }
  .information-lhs {
    align-self: center;
    width: 80%;
    order: 2;
  }

  .information-rhs {
    width: 100%;
  }
  img {
    width: 100%;
  }

  .homepage-container {
    top: 5vh;
  }

  .homepage-image-container {
    flex-direction: column;
  }

  .modal-overlay h2 {
    max-width: unset !important;
    padding: 0;
  }

  .ReactModal__Content {
    padding: 0 !important;
  }

  .modal-overlay .x {
    top: 5px;
    right: 5px;
  }
}
